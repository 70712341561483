import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"

import SingleLayout from '../components/layouts/SingleLayout_10'
import SingleCard from '../components/cards/card'
import Seo from "../components/seo"
// import Img from "gatsby-image"
const _ = require("lodash");

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges } = data.allMarkdownRemark
  // const tagHeader = `${totalCount} post${
  //   totalCount === 1 ? "" : "s"
  // } tagged with "${tag}"`
  const title = `Explore posts for tag: ${tag}`;
  return (
    <SingleLayout>
      <Seo
        title={title}
        description={title}
      />
      <div className="w-lg-60 mx-lg-auto">
        <div className="mb-2">
          <h1 className="h1 mb-4">
            Explore Posts for <span class="text-primary text-highlight-warning">
              {_.startCase(_.toLower(tag))}
              </span>
          </h1>
        </div>
      </div>

      <div className="w-lg-80 mx-lg-auto">
        <div class="row mb-3">
          {edges.map(({ node }) => {
            const { slug } = node.fields
            const { title } = node.frontmatter
            return (
              <SingleCard key={slug} title={title} href={slug} 
                desc={node.excerpt} img={node.frontmatter.featuredImage.childImageSharp.fluid} />
            )
          })}
        </div>
        <Link to="/tags">All tags</Link>
      </div>
    </SingleLayout>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 80)
          frontmatter {
            title
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 350) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`